"use strict";

var screenType = function () {
  if (window.innerWidth <= 801 || (window.innerHeight <= 801 && window.innerWidth <= 1024)) {
    return "mobile"
  } else {
    return "desktop"
  }
};

var getIOSVersion = function () {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (v) {
      return [ parseInt(v[ 1 ], 10), parseInt(v[ 2 ], 10), parseInt(v[ 3 ] || 0, 10) ];
    } else {
      return undefined;
    }
  }
}

var searchTypeMap = {
  photos: 'people',
  events: 'events',
};

if (window.location.hash.indexOf('#close_window') > -1) {
  if (window.opener && window.location.hash.indexOf('twitter') > -1) {
    window.opener.twitter.OAuthCallback(window.location.search + window.location.hash);
  }
  window.close();
}

var da = {};

if (!window.location.origin) { // Fix for IE where there is no location.origin
  window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
}

if (window.location.pathname.indexOf('checkout') === -1) { // clear cart data if not checkout page
  sessionStorage.removeItem('data');
}

if (document.body.ontouchstart !== undefined && document.body.ontouchstart !== null) {
  document.querySelector('html').classList.add('touch');
}

da.topBanner = {};
da.currentScreenType = screenType();
da.iosVersion = getIOSVersion();
da.$body = $('body');
da.$footer = $('#footer');
da.$searchContainer = $('.js-event-photos');
da.items = [];
da.eventID = da.$searchContainer.data('event-id');
da.eventDescription = da.$searchContainer.data('event-description');
da.eventTitle = $('.js-event-title').text();
da.galleryID = da.$searchContainer.data('gallery-id');
da.lightboxID = da.$searchContainer.data('lightbox-id');
da.photoPreview = {};
da.photoPreview.currentPhoto = {};
da.photoPreview.currentPhoto.arrayToPreload = [];
da.photoPreview.$el = $($('#tpl-photo-preview').html());
da.ecommerce = {};
da.ecommerce.$el = da.photoPreview.$el.length > 0 ? da.photoPreview.$el.find('.js-ecommerce-block') : $('.js-ecommerce-block');
da.ecommerce.purchaseOptions = {
  prints: {
    $head: $(da.ecommerce.$el.find('.js-head-prints')),
    $opts: $(da.ecommerce.$el.find('#prints .js-option-id')),
    $el: $(da.ecommerce.$el.find('#prints'))
  },
  downloads: {
    $head: $(da.ecommerce.$el.find('.js-head-downloads')),
    $opts: $(da.ecommerce.$el.find('#downloads .js-option-id')),
    $el: $(da.ecommerce.$el.find('#downloads'))
  }
};

if (da.ecommerce.$el) {
  if (da.ecommerce.purchaseOptions.downloads.$opts.length === 0 &&
    da.ecommerce.purchaseOptions.prints.$opts.length === 0) {
    da.ecommerce.$el.remove();
  } else {
    if (da.ecommerce.purchaseOptions.prints.$opts.length === 0) {
      da.ecommerce.purchaseOptions.prints.$head.remove();
      da.ecommerce.purchaseOptions.prints.$el.remove();
      da.ecommerce.purchaseOptions.downloads.$head.addClass('active');
      da.ecommerce.purchaseOptions.downloads.$el.addClass('active');
    }

    if (da.ecommerce.purchaseOptions.downloads.$opts.length === 0) {
      da.ecommerce.purchaseOptions.downloads.$head.remove();
      da.ecommerce.purchaseOptions.downloads.$el.remove();
    }
  }
}
da.photoPreview.$downloadImage = da.photoPreview.$el.find('.js-download-image');
da.photoPreview.image = da.photoPreview.$el.find('#image-preview-url');
da.photoPreview.inCart = $('.js-photo-page').length ? $(('.js-in-cart')) : da.photoPreview.$el.find('.js-in-cart');
da.photoPreview.peopleList = da.photoPreview.$el.find('.js-people-list');
da.photoPreview.downloadLink = da.photoPreview.$el.find('.js-download-link');
da.photoPreview.$tabFooter = $('.js-tab-footer');
da.peopleFilter = {
  name: $('.filtered-person-name'),
  block: $('.filter-block.people')
};
da.tagFilter = {
  name: $('.filtered-tag-name'),
  block: $('.filter-block.tag')
};
da.cart = {};
da.cart.productToAdd = {};
da.cart.inCartItems = window.inCartItems;
da.currentPerson = {};
da.popupIsVisible = false;
da.scrolledPosition = null;

if (da.iosVersion && da.iosVersion[ 0 ] === 11) {
  da.$body.addClass('ios-11')
}

if (da.currentScreenType === 'mobile') {
  $('.logo-link').addClass('active');
}

if ($('.js-search-form-global').length) {
  da.globalSearch = {
    $el: $('.js-search-form-global'),
    $options: $('.js-search-form-global .js-category-list li a'),
    $searchBtn: $('.js-search-form-global .js-perform-global-search'),
    $searchInput: $('.js-search-form-global .js-search-input'),
    $activeBox: $('.js-search-form-global .js-category-link'),
    searchType: $('.js-search-form-global .js-category-link').data('search-type')
  };

  da.globalSearch.$activeBox.on('click', function (e) {
    e.preventDefault();
  });

  da.globalSearch.$searchInput.on('keyup', function (e) {
    if (e.keyCode === 13) {
      da.globalSearch.$searchBtn.trigger('click');
    }
  });

  da.globalSearch.$searchBtn.on('click', function (e) {
    e.preventDefault();
    location.href = '/search?type=' + da.globalSearch.searchType +
      (da.globalSearch.$searchInput.val() ? ('&query=' +
        da.globalSearch.$searchInput.val()) : '');
  });

  da.globalSearch.$options.on('click', function (e) {
    e.preventDefault();
    da.globalSearch.$el.removeClass('open');
    da.globalSearch.searchType = this.dataset.searchOption;
    da.globalSearch.$activeBox.data('search-type', da.globalSearch.searchType).find('span').text(searchTypeMap[da.globalSearch.searchType]);
  });
}

/*preloading of image*/
function preload(imgArr) {
  $(imgArr).each(function () {
    (new Image()).src = this;
  });
}

da.photoPreview.generatePhotoData = function () {
  revertOptionCheck(true);
  da.photoPreview.image.hide();
  da.photoPreview.image.attr('src', '');
  da.photoPreview.inCart.hide();
  if (da.cart.inCartItems[ da.photoPreview.currentPhoto.obj.flatID ]) da.photoPreview.inCart.show();

  setTimeout(function () {
    da.photoPreview.image.attr('src', da.photoPreview.currentPhoto.obj.watermarkURL).load(function () {
      preload(da.photoPreview.currentPhoto.arrayToPreload);
      da.photoPreview.currentPhoto.arrayToPreload = [];
    });
    da.photoPreview.image.show();
  }, 1);
  da.photoPreview.$el.data('id', da.photoPreview.currentPhoto.id);
  da.photoPreview.image.attr({
    'alt': da.photoPreview.currentPhoto.obj.altTag,
    'title': da.photoPreview.currentPhoto.obj.titleTag
  });
  da.photoPreview.photo.attr('title', da.photoPreview.currentPhoto.obj.titleTag);
  da.photoPreview.$downloadImage.length ? da.photoPreview.$downloadImage.attr('href', da.photoPreview.currentPhoto.downloadURL) : '';
  var currentPeopleList = '';
  for (var item in da.photoPreview.currentPhoto.obj.people) {
    if (String(da.photoPreview.currentPhoto.obj.people[ item ].id).length && typeof da.photoPreview.currentPhoto.obj.people[ item ].id != "object") {
      currentPeopleList += "<a  class='more-photos js-more-photos " + (da.photoPreview.currentPhoto.obj.people[ item ].isVIP ? "vip" : "")
        + "' href='/search?type=photos&people=" + da.photoPreview.currentPhoto.obj.people[ item ].publicID
        + "'data-person-id='" + da.photoPreview.currentPhoto.obj.people[ item ].publicID
        + "'data-person-flat-id='" + da.photoPreview.currentPhoto.obj.people[ item ].id
        + "'data-person-name='" + da.photoPreview.currentPhoto.obj.people[ item ].publicName
        + "'>" + da.photoPreview.currentPhoto.obj.people[ item ].publicName
        + "<span class='additional-actions js-additional-actions'><span class='js-show-more-photos show-more-photos'>show more</span> of " + da.photoPreview.currentPhoto.obj.people[ item ].publicName + " photos or <span class='correct-name js-correct-name-photo'>correct name</span></span></a>";
    }
    else {
      currentPeopleList += "<a href='#' class='js-tag-people js-popup' data-type='tag' data-person-position='"
        + da.photoPreview.currentPhoto.obj.people[ item ].position + "' data-person-id='"
        + da.photoPreview.currentPhoto.obj.people[ item ].id + "'>"
        + da.photoPreview.currentPhoto.obj.people[ item ].publicName + "</a>";
    }
    item != da.photoPreview.currentPhoto.obj.people.length - 1 ? currentPeopleList += ", " : ""
  }
  da.photoPreview.peopleList.html(currentPeopleList);
  da.photoPreview.downloadLink.attr('href', da.photoPreview.currentPhoto.obj.downloadSubscribedURL);
  if (da.photoPreview.currentPhoto.obj.freeDownloadURLs && da.ecommerce.purchaseOptions.downloads.$el) {
    da.photoPreview.currentPhoto.obj.freeDownloadURLs.forEach(function (item, index) {
      da.ecommerce.purchaseOptions.downloads.$el.find('[data-option-id=' + item.id + '] a').attr('href', item.url);
    })
  }
};

function prepareDirectDownloadLinks(options, withLinks) {
  options.forEach(function (item) {
    var downloadLink = da.ecommerce.purchaseOptions.downloads.$el.find('[data-option-id=' + item.id + '] a');
    if (downloadLink) {
      downloadLink.css('display', 'inline-block').siblings(".js-price").hide().siblings(".js-to-cart-option").prop('disabled', true).parent().addClass('emulate-check');
      withLinks ? downloadLink.attr('href', item.url) : '';
    }
  });
}

function checkAddToCartBtn(type) {
  var freeOptions = [];
  if (type === 'downloads') {
    freeOptions = window.freeDownloadURLs ? window.freeDownloadURLs : [];
  }
  $('.js-add-to-cart').toggleClass('disabled', da.ecommerce.purchaseOptions[type].$opts.length === freeOptions.length);
}

da.photoPreview.definePhotoObject = function (id) {
  for (var item in da.items) {
    if (
      da.items[ item ][ 'flatID' ] == id) {
      da.photoPreview.currentPhoto.obj = da.items[ item ];
      break;
    }
  }
};

da.photoPreview.getImageUrl = function (id) {
  for (var item in da.items) {
    if (
      da.items[ item ][ 'flatID' ] == id) {
      return da.items[ item ][ 'watermarkURL' ]
    }
  }
};

da.photoPreview.getNeighbours = function () {
  da.photoPreview.currentPhoto.$nextObjects = $(((da.photoPreview.currentPhoto.$el.nextAll()).add(da.photoPreview.currentPhoto.$el.parent().nextAll('.search-row').children())).not('.cap'));
  da.photoPreview.currentPhoto.$prevObjects = $(((da.photoPreview.currentPhoto.$el.prevAll()).add(da.photoPreview.currentPhoto.$el.parent().prevAll('.search-row').children())).not('.cap'));
};

var Popup = null;
if ($('#tpl-popup').length) {
  Popup = Ractive.extend({
    el: document.body,
    append: true,
    template: '#tpl-popup'
  });
}
var Modal = null;
if ($('#tpl-modal').length) {
  Modal = Ractive.extend({
    el: document.body,
    append: true,
    template: '#tpl-modal'
  });
}
var ModalMessage = null;
if (Modal) {
  ModalMessage = Modal.extend({
    partials: {
      modalContent: '{{>\'tpl-message\'}}'
    },
    onrender: function () {
      var t = this;
      t.on('close', function () {
        t.teardown();
      });
    }
  });
}
/**
 *
 * @param r - response from server
 * @param tout - timeout to fadeout the modal
 */
da.modalMessage = function (r, tout) {
  var timeout = tout || false;
  var type = r.isSuccess && r.response.status === 200 ? 'success' : 'danger';
  var msg = r.response.message || r.response.error || (type === 'success' ? 'Success' : 'An error has occurred');
  var modalMessage = new ModalMessage;
  modalMessage.set('message', msg);
  modalMessage.set('type', type);
  if (timeout) {
    setTimeout(function () {
      modalMessage.teardown()
    }, timeout)
  }
};

da.modalCustomMessage = function (customMessage, customType, tout) {
  var timeout = tout || false;
  var msg = customMessage;
  if (customType) {
    var type = customType
  } else {
    type = "danger"
  }
  var modalMessage = new ModalMessage;
  modalMessage.set('message', msg);
  modalMessage.set('type', type);
  if (timeout) {
    setTimeout(function () {
      modalMessage.teardown()
    }, timeout)
  }
};
//tagPosition - custom

da.performFacebookSharing = function (albumID, message, t, e) {
  FB.api('/' + albumID + '/photos', 'post', {
    message: message,
    url: t.get('imageURL'),
    // url: "https://media.drewaltizer.com/2543-San-Francisco-Opera-Ball-2017/59b38ce3a9ecf-1007-OperaBall-170908-watermark.jpg",
    access_token: FB.getAuthResponse().accessToken
  }, function (response) {
    da.popupIsVisible = false;
    t.teardown();
    response.error
      ? da.modalCustomMessage(e.node.dataset.error, 'danger', 2000)
      : da.modalCustomMessage(e.node.dataset.success, 'success', 2000);

  });
};

da.popup = function (type, custom) {
  custom = typeof custom !== 'undefined' ? custom : false;
  da.popupIsVisible = true;
  var popup = new Popup({
    data: {
      type: type,
      fade: true, //to prevent crashing of IE
      scrolledPosition: null
    },
    onrender: function () {
      var t = this;
      t.set('scrolledPosition', window.scrollY);
      da.$body.addClass('with-modal');
      if (this.get('type') === 'share-link') {
        var clipboardButton = new Clipboard("#clipboard-button");
        clipboardButton.on('success', function (e) {
          var btn = event.target;
          var btnText = btn.innerHTML;
          btn.innerHTML = btn.dataset.success;
          setTimeout(function () {
            btn.innerHTML = btnText;
          }, 3000);
        });
      }
      t.on('facebook-share-image', function (e, message) {
        e.node.innerText = e.node.dataset.progress;
        e.node.disabled = true;
        var albumID = null;
        //check for album with name
        FB.api(
          "/me/albums",
          function (response) {
            if (response && !response.error) {
              response.data.some(function (album, index) {
                if (album.name === "Drew Altizer Photography images") {
                  albumID = album.id;
                  da.performFacebookSharing(albumID, message, t, e);
                  return true;
                }
              });
              if (!albumID) {
                //if doesn't exist - then to create such album
                FB.api('/me/albums', 'post', {
                  name: "Drew Altizer Photography images"
                }, function (resp) {
                  if (resp && !resp.error) {
                    albumID = resp.id;
                    da.performFacebookSharing(albumID, message, t, e);
                  } else {
                    da.popupIsVisible = false;
                    t.teardown();
                    if (resp.error && resp.error.message) {
                      da.modalCustomMessage(e.node.dataset.error, 'danger', 2000)
                    }
                  }
                });
              }
            }
          }
        );
      });
      if (this.get('type') === 'mail-link') {
        var that = this;
        window.closePopup = function () {
          if (!da.popupIsVisible) return;
          da.popupIsVisible = false;
          //to fix crashes of IE
          that.set('fade', false).then(function () {
            that.teardown();
            da.$body.removeClass('with-modal');
            if (da.iosVersion && da.iosVersion[ 0 ] === 11) window.scrollTo(0, that.get('scrolledPosition'));
          });
        }
      }
    }
  });
  popup.on({
    closePopup: function (e) {
      e.original.preventDefault();
      da.popupIsVisible = false;
      //to fix crashes of IE
      popup.set('fade', false).then(function () {
        popup.teardown();
        da.$body.removeClass('with-modal');
        if (da.iosVersion && da.iosVersion[ 0 ] === 11) window.scrollTo(0, popup.get('scrolledPosition'));
      });
    },
    switchSignType: function (e) {
      e.original.preventDefault();
      popup.set('type', popup.get('type') === "signin" ? "signup" : "signin");
    },
    facebookLogin: facebookLogin
  });
  //custom
  if (type == 'tag') {
    popup.set('peoplePosition', custom);
    popup.set('photoID', da.photoPreview.currentPhoto.id);
  }
  if (type == 'name-correction') {
    popup.set('personID', da.currentPerson.$id || custom.personID);
    popup.set('personName', da.currentPerson.$name || custom.personName);
    popup.set('fromPhoto', (custom && custom.fromPhoto) ? true : false);
    popup.set('photoID', da.photoPreview.currentPhoto.id);
  }
  if (type == 'mail-link') {
    popup.set('photoID', da.photoPreview.currentPhoto.id);
  }
  if (type == 'share-link') {
    popup.set('photoURL', da.photoPage ? window.location.href : da.photoPreview.currentPhoto.obj.publicURL).then(function (e) {
      document.getElementById("clipboard-button").dataset.clipboardText = document.getElementById('embed-link').value;
    });
  }
  if (type == 'facebook-share') {
    spiral.ajax({
      'url': '/share/getShareFields/' + ((typeof da.photoPage !== 'undefined') ? da.photoPage.el.dataset.id : da.photoPreview.currentPhoto.obj.id),
      data: {
        'personalSharing': 1
      },
      processAnswer: function (result) {
        if (!result.isSuccess || result.response.status !== 200) {
          da.modalMessage(result, 2000);
          return;
        }
        popup.set('imageURL', result.response.fields.imageURL);
      }
    });

  }
  if (type == 'preview-attachment') {
    popup.set('attachment', custom);
  }
  if ((type == 'signin' || type == 'signup') && custom == "reload") {
    popup.set('reload', true);
  }
  if (type == 'cropper-preview-popup' && custom) {
    popup.set('imageSource', custom.previewImage);
    popup.set('maxWidth', custom.maxWidth);
    popup.set('maxHeight', custom.maxHeight);
  }
};

$(document).on('click', '.filter-list.people a', function (e) {
  e.preventDefault();
  da.currentPerson.$id = $(this).data('id');
  da.currentPerson.$name = $(this).text();
  da.$body.removeClass('filter-shown');
  if (da.currentScreenType === 'mobile') $('.js-sort-list').hide();
});

$(document).on('click', '.js-popup', function (e) {
  e.preventDefault();
  da.popup(this.dataset.type || "signin", this.dataset.personPosition);
});

$(document).on('click touchstart', '.js-additional-actions', function (e) {
  e.preventDefault();
  e.stopPropagation();
});

$(document).on('click touchstart', '.js-show-more-photos', function (e) {
  e.preventDefault();
  e.stopPropagation();
  location.href = $(this).parent().parent().attr('href');
});

$(document).on('click touchstart', '.js-correct-name-photo', function (e) {
  e.stopPropagation();
  e.preventDefault();
  da.popup("name-correction", {
    'personID': this.parentNode.parentNode.dataset.personFlatId,
    'personName': this.parentNode.parentNode.dataset.personName,
    'fromPhoto': true
  });
});

$('.js-link').on('click', function (e) {
  e.preventDefault();
  spiral.ajax({
    "url": this.getAttribute('href'),
    processAnswer: function (result) {
      if (result.response.message) da.modalMessage(result, 5000);
    }
  });
});

$('.js-share').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('active');
  $('.social-block').toggleClass('active');
});

$('.js-help-link').on('mouseenter', function () {
  $('.js-help-text').addClass('show');
});

$('.js-help-link').on('mouseleave', function () {
  $('.js-help-text').removeClass('show');
});
/* tabs */
$(document).on('click', '.js-head a:not(active)', function (e) {
  e.preventDefault();
  var href = $(this).attr('href');
  revertOptionCheck();
  $('.js-head a').removeClass('active');
  $('.js-body .tab-block').removeClass('active');
  $(this).addClass('active');
  $(href).addClass('active');
  checkAddToCartBtn(href.substring(1));
});

$(document).on('click', '.cap', function (e) {
  e.preventDefault();
});

$(document).on('change', '.js-to-cart-option', function () {
  var thisActive = $(this).hasClass('checked');
  //revertOptionCheck();
  if (thisActive) {
    $(this).closest('.photo-size').find('.js-photo-amount').removeClass('show');
    $(this).removeAttr('checked').removeClass('checked');
    da.cart.productToAdd.id = '';
    da.cart.productToAdd.quantity = '';
    da.cart.productToAdd.optionID = '';
  } else {
    if ($(this).closest('#downloads').length) $('.js-to-cart-option').removeAttr('checked').removeClass('checked');
    $(this).closest('.photo-size').find('.js-photo-amount').addClass('show');
    $(this).attr('checked', 'checked').addClass('checked').prop('checked', true);
    $(this).find('.js-photo-amount').find('input').val('1');
  }
  revertCartButton();
});

$(document).on('click', '.js-photo-amount a', revertCartButton);

function revertCartButton() {
  $('.js-tab-footer').addClass('add-to-cart-active');
}

if (da.currentScreenType === 'mobile') {
  $(document).on('scroll', function () {
    var $window = $(window);
    var ecommerceBlockBody = $('.js-ecommerce-block .js-body');
    if (!ecommerceBlockBody.length) return;
    var isStatic = da.photoPreview.$tabFooter.hasClass('static');
    if ($window.scrollTop() + $window.height() >= ecommerceBlockBody.offset().top + ecommerceBlockBody.outerHeight() + da.photoPreview.$tabFooter.height()) {
      if (!isStatic) da.photoPreview.$tabFooter.addClass('static');
    } else {
      if (isStatic) da.photoPreview.$tabFooter.removeClass('static');
    }
  });
}

$(document).on('change', '.js-select-material', function () {
  revertCartButton();
});

function revertOptionCheck(withTab) {
  withTab = typeof withTab !== 'undefined';
  revertCartButton();
  $('.js-copies').removeClass('plural');
  $('.js-photo-amount input').val('1');
  $('.js-photo-amount.show').removeClass('show');
  $('.js-to-cart-option').removeAttr('checked').removeClass('checked');
  $('.js-price-value').each(function () {
    $(this).text($(this).data('default'));
  });
  if (withTab) {
    $('.tab-block').removeClass('active').first().addClass('active');
    $('.js-head a').removeClass('active').first().addClass('active');
  }
}

$(document).on('click', '.js-modal-link', function (e) {
  e.preventDefault();
  var linkId = $($(this).attr('href'));
  linkId.show();
  da.$body.addClass('modal-show').append('<div id="fade"></div>');
});

$('.js-modal-close').on('click', function (e) {
  e.preventDefault();
  $(this).closest('.modal').hide();
  da.$body.removeClass('modal-show');
  $('#fade').remove();
});

$('.filter-options').on('mouseleave', function (e) {
  e.preventDefault();
  $('.filter-options').hide().removeClass('active');
});

$('.close-select').on('click', function (e) {
  e.preventDefault();
  $('.filter-options').hide();
});

//$(document).on('click', function(e) {
//    if ($(e.target).closest('.filter-options').length) return;
//    $('.filter-options').hide();
//});
//

$(document).on('click touchstart', function (e) {

  if ((!$(e.target).closest('.filter-options').length && e.target.tagName.toLowerCase() == 'a') ||
    (!$(e.target).closest('.filter-options').length && !($(e.target).hasClass('mobile-sort-icon')))) {
    $('.filter-options').hide();
  }
  if (da.currentScreenType === 'desktop') return;
  if ($(e.target).closest('.js-sort-list').length === 0 && $('.js-sort-list').is(':visible') && !$(e.target).closest('.js-sort').length) {
    $('.js-sort-list').hide();
    da.$body.removeClass('filter-shown');
  }
});

var touchIE = window.navigator.pointerEnabled ? "click" : (window.navigator.msPointerEnabled ? "click" : "mouseenter touchend");
$(document).on(touchIE, '.js-show-filter', function (e) {
  e.preventDefault();
  e.stopPropagation();
  if ($(this).closest('.filter-block').find('.filter-options').hasClass('active')) {
    $('.filter-options').hide().removeClass('active');
  } else {
    $('.filter-options').hide().removeClass('active');
    $(this).closest('.filter-block').find('.filter-options').show().addClass('active');
  }
  if (!$('html').hasClass('touch')) {
    $(this).closest('.filter-block').find('.filter-input').focus();
  }
});

$('.js-category-link').on('click', function () {
  $('.js-category-list').addClass('open');
});

$(document).on('click', function (e) {
  if (!($(e.target).closest('.js-category-link').length)) {
    $('.js-category-list').removeClass('open');
  }
});

$('.js-mobile-menu').on('click', function (e) {
  e.preventDefault();

  if ($(this).hasClass('open')) {
    $('.js-navigation').removeClass('show');
    da.$body.removeClass('menu-open');
    $(this).removeClass('open');
    $('#fade-m').remove();
  } else {
    $('.js-navigation').addClass('show');
    da.$body.addClass('menu-open');
    $(this).addClass('open');
    da.$body.append("<div id='fade-m'></div>");
  }
});

$('.js-sort').on('click', function (e) {
  e.preventDefault();
  $('.js-sort-list').animate({
    width: "toggle",
    height: "toggle"
  }, 250, 'swing', function () {
    da.scrolledPosition = window.scrollY;
    da.$body.toggleClass('filter-shown');
  });
});

$(document).on('click', '.js-close-sort-list', function (e) {
  e.preventDefault();
  $('.js-sort-list').hide();
  da.$body.removeClass('filter-shown');
  if (da.iosVersion && da.iosVersion[ 0 ] === 11) window.scrollTo(0, da.scrolledPosition);
});

$('.js-search').on('click', function (e) {
  e.preventDefault();
  var $searchForm = $('.js-search-form');

  if ($searchForm.is(':visible')) {
    $('.sort-link').show();
    $('#fade-m').remove();
  } else {
    $('.sort-link').hide();
    da.$body.append("<div id='fade-m'></div>");
  }

  $('.js-navigation').removeClass('show');
  $searchForm.animate({
    width: "toggle",
    opacity: "toggle"
  }, 250, 'swing');

  $('.js-sort-list:visible').animate({
    width: "toggle",
    height: "toggle"
  }, 250, 'swing', function () {
    da.$body.toggleClass('filter-shown');
  });
});

$(document).on('click touchend touchmove', '#fade-m', function (e) {
  $('.sort-link').show();
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  $(this).remove();
  $('.js-search-form:visible').animate({
    width: "toggle",
    opacity: "toggle"
  }, 250, 'swing');
  $('.js-mobile-menu').hasClass('open') ? $('.js-mobile-menu').trigger('click') : '';
});

$('.js-menu-hide').on('click', function (e) {
  e.preventDefault();
  $('.js-navigation').removeClass('show');
  da.$body.removeClass('menu-open');
});

$('.js-clear-people-filter').on('click touchstart', function (e) {
  e.preventDefault();
  $('#filter-people').val("").trigger('input');
  da.peopleFilter.name.text(da.peopleFilter.name.data('default'));
  da.peopleFilter.block.removeClass('active');
  checkClearAllFilters();
});

// $('.js-clear-tag-filter').on('click', function (e) {
//     e.preventDefault();
//     $('#filter-tag').val("").trigger('input');
//     da.tagFilter.name.text(da.tagFilter.name.data('default'));
//     da.tagFilter.block.removeClass('active');
// });

$('.js-clear-all-filters').on('click touchstart', function (e) {
  da.clearAllFlag = true;
  e.preventDefault();
  $('.js-clear-date-filter, .js-clear-people-filter, .filter-list.sort-by>:first').trigger('click');
  // var time = 0;
  // $('.js-clear-people-filter, .js-clear-date-filter, .filter-list.sort-by>:first').
  //     each(function() {
  //       var that = this;
  //       setTimeout(function() {
  //         $(that).trigger('click');
  //       }, time);
  //       time += 300;
  //     });
  $(this).parent().hide();
  if (da.currentScreenType === 'mobile') {
    setTimeout(function () {
      $('.js-sort-list').hide();
      da.$body.removeClass('filter-shown');
    }, 100);
  }
});

function checkClearAllFilters() {
  if (!(da.peopleFilter.block.hasClass('active') || $('.filter-block.date').hasClass('active'))) {
    $('.js-clear-all-filters').parent().hide();
  }
}

$(document).on('click', '.js-more', function (e) {
  e.preventDefault();
  var $currentInput = $(this).prev('input');
  var $currentText = $(this).parent('.photo-amount').find('.js-copies');
  var $price = $(this).parent().siblings('.js-price-value');
  if ($currentInput.val().length) {
    $currentInput.val(parseInt($currentInput.val()) + 1);
    $currentInput.val() == 2 ? $currentText.addClass('plural') : '';
    if (!Number.isNaN(parseFloat($price.data('default').substr(1))))
      $price.text('$' + Math.round(parseFloat($price.data('default').substr(1)) * $currentInput.val() * 100) / 100);
  }
  else {
    $currentInput.val(1);
    $('.js-copies').removeClass('plural');
  }
});

$(document).on('click touchstart', '.js-less', function (e) {
  e.preventDefault();
  var $currentInput = $(this).next('input');
  var $currentText = $(this).parent('.photo-amount').find('.js-copies');
  var $price = $(this).parent().siblings('.js-price-value');
  if ($currentInput.val().length) {
    parseInt($currentInput.val()) > 1 ? $currentInput.val(parseInt($currentInput.val()) - 1) : '';
    $currentInput.val() == 1 ? $currentText.removeClass('plural') : '';
    if (!Number.isNaN(parseFloat($price.data('default').substr(1))))
      $price.text('$' + Math.round(parseFloat($price.data('default').substr(1)) * $currentInput.val() * 100) / 100);
  }
  else {
    $currentInput.val(1);
    $('.js-copies').removeClass('plural');
  }
});

$(document).on('focus', '.js-photo-amount input', function (e) {
  if (da.currentScreenType === 'mobile') $(this).blur();
});

$(document).on('blur', '.js-photo-amount input', function (e) {
  if ($(this).val() < 1) $(this).val(1);
  $('.js-copies').removeClass('plural');
  var $price = $(this).parent().siblings('.js-price-value');
  if (!Number.isNaN(parseFloat($price.data('default').substr(1))))
    $price.text('$' + Math.round(parseFloat($price.data('default').substr(1)) * this.value * 100) / 100);
});

$(document).on('input', '.photo-amount input', function (e) {
  this.value = this.value.replace(/[^0-9]/g, "");
  this.value != 1 && this.value != "" ? $('.js-copies').addClass('plural') : $('.js-copies').removeClass('plural');
  var $price = $(this).parent().siblings('.js-price-value');
  if (!Number.isNaN(parseFloat($price.data('default').substr(1))))
    $price.text('$' + Math.round(parseFloat($price.data('default').substr(1)) * this.value * 100) / 100);
});

function updateCartAmount(val) {
  da.$cartAmount = $('.js-cart-amount');
  da.$cartAmount.text(val);
  val > 0 ? da.$cartAmount.parent().removeClass('hidden') : '';
}

da.itemsToCart = {};
da.itemsToCart.optionID = [];
da.itemsToCart.quantity = [];

da.cart.addToCart = function (itemID, quantity, optionID, material) {
  if (da.itemsToCart.optionID.length > 0) {
    spiral.ajax({
      "url": "/checkout/addItem",
      data: {
        itemID: itemID,
        quantity: da.itemsToCart.quantity[ 0 ],
        optionID: da.itemsToCart.optionID[ 0 ],
        material: material,
        eventID: da.eventID
      },
      processAnswer: function (result) {
        da.modalMessage(result, 2000);
        if (!result.isSuccess || result.response.status !== 200) return;
        $('.js-tab-footer').removeClass('add-to-cart-active');
        updateCartAmount(result.response.cart.total.summary);
        da.photoPreview.inCart.show();
        if (!da.photoPage) {
          da.cart.inCartItems[ itemID ] = da.photoPreview.currentPhoto.obj.id;
          $('.js-image-preview[data-id=' + itemID + ']').prepend($('<i class="icon icon-basket in-cart" title="Photo is in cart"></i>'));
        }
        da.itemsToCart.quantity.shift();
        da.itemsToCart.optionID.shift();
        if (da.itemsToCart.optionID.length) da.cart.addToCart(itemID, quantity, optionID, material);
      }
    });
  } else {
    $("html, body").animate({ scrollTop: da.ecommerce.$el.offset().top + da.ecommerce.$el.height() / 2 - screen.height / 2 }, 200);
    da.modalCustomMessage(event.target.dataset.error, 'danger', 2000)
  }
};

$(document).on('click', '.js-add-to-cart', function (e) {
  e.preventDefault();
  if ($(this).hasClass('disabled')) return;
  var selectedItems = $('.js-body input.checked');
  selectedItems.each(function () {
    da.cart.productToAdd.activeInput = $(this);
    if (da.photoPreview.$el.length) {
      da.cart.productToAdd.id = da.photoPreview.$el.data('id')
    } else {
      da.cart.productToAdd.id = $('#photo-preview').data('id')
    }
    da.cart.productToAdd.material = $('.js-select-material').val();
    da.cart.productToAdd.optionID = da.cart.productToAdd.activeInput.closest('.js-option-id').data('option-id');

    if (da.cart.productToAdd.activeInput.hasClass('js-photo-check')) {
      da.cart.productToAdd.quantity = parseInt(da.cart.productToAdd.activeInput.parent().next('.js-photo-amount').find('input').val());
    } else {
      da.cart.productToAdd.quantity = 1;
    }
    da.itemsToCart.quantity[ da.itemsToCart.quantity.length ] = da.cart.productToAdd.quantity;
    da.itemsToCart.optionID[ da.itemsToCart.optionID.length ] = da.cart.productToAdd.optionID;
  });
  da.cart.addToCart(da.cart.productToAdd.id, da.itemsToCart.quantity, da.itemsToCart.optionID, da.cart.productToAdd.material)
  revertOptionCheck();

  var image = (da.photoPage) ? $('.photo-block>img') : $('#image-preview-url');

  var width = image.width();
  var height = image.height();
  var $cartIcon = $('.icon-cart');
  var left = $cartIcon.offset().left + $cartIcon.width();
  var el = (da.photoPage) ? document.querySelector('.photo-block').getElementsByTagName('img')[ 0 ] : document.getElementById('image-preview-url');

  if (selectedItems.length) {
    image.clone().appendTo('body').removeAttr('id').addClass('clone-image').css({
      top: el.getBoundingClientRect().top + 'px',
      left: el.getBoundingClientRect().left + 'px',
      width: width,
      height: height,
      position: 'fixed'
    });

    $('.clone-image').animate({
      top: '26px',
      left: left,
      opacity: 0,
      height: 0,
      width: 0
    }, 1000, function () {
      //$(this).remove();
    });
  }
});

$(document).on('mousemove', '.js-show-tooltip', function (e) {
  e.preventDefault();
  var that = $(this);
  var tooltip = that.find('.tooltip-wrapper');
  var top = e.pageY - that.offset().top + 25;
  var left = e.pageX - that.offset().left + 15;

  tooltip.css(
    {
      'top': top,
      'left': left
    }
  );
});

$('.js-add-plan').on('click', function (e) {
  var button = this;
  e.preventDefault();
  var planID = $(this).data('plan-id');
  spiral.ajax({
    "url": "/checkout/addPlan/",
    data: {
      planID: planID
    },
    processAnswer: function (result) {
      if (!result.isSuccess || result.response.status !== 200) {
        da.modalMessage(result, 2000);
      } else {
        updateCartAmount(result.response.cart.total.summary);
        location.href = '/checkout';
        $(button).val(button.dataset.addText).addClass('added').unbind('click').on('click', function (e) {
          e.preventDefault();
          location.href = "/checkout";
        });
      }
    }
  });
});

if ($('.js-photo-page').length) {
  da.photoPage = {
    $backLink: $('.back-link')
  };
  da.photoPage.el = document.getElementById('photo-preview');
  da.eventID = da.photoPage.el.dataset.eventId;
  da.photoPage.watermarkURL = da.photoPage.el.dataset.photo;
  da.photoPage.subscribedURL = da.photoPage.el.dataset.subscribedPhoto;
  da.photoPage.eventDescription = da.photoPage.el.dataset.eventDescription;
  if (document.referrer.indexOf("search?type=photos") || document.referrer.indexOf("search?type=events") > -1) {
    da.photoPage.$backLink.text(da.photoPage.$backLink.data('search-results')).attr('href', document.referrer);
  }
  prepareDirectDownloadLinks(freeDownloadURLs, true);
  checkAddToCartBtn($('.js-ecommerce-block .tab-block.active').attr('id'));
}
if ($('#bookus').length && typeof Pikaday !== 'undefined') {
  var picker = new Pikaday({
    field: document.getElementsByClassName('datepicker')[ 0 ],
    minDate: new Date()
  });

  $('#timepicker-wrapper .time').timepicker({
    'showDuration': true,
    'timeFormat': 'h:i A',
    'disableTextInput': true
  });

  var timeOnlyExampleEl = document.getElementById('timepicker-wrapper');
  var timeOnlyDatepair = new Datepair(timeOnlyExampleEl);

  $('input').on('focus', function (e) {
    if (!$(this).hasClass('time')) $('#timepicker-wrapper .time').timepicker('hide');
  });

  var bookingSuccess = function (r) {
    if (r.isSuccess && r.response.status === 200) {
      da.popup('booking');
      var $bookUsBtn = $('.js-book-us');

      $bookUsBtn.val($bookUsBtn.data('add-text')).on('click', function (e) {
        e.preventDefault();
        location.href = "/all-events";
      });
    } else {
      $('html,body').animate(
        {
          scrollTop: $("main .js-spiral-form").offset().top - $('header').outerHeight(true)
        },
        300);
    }
  }
}

$(document).on('click', '.js-facebook-link', function (e) {
  e.preventDefault();
  //FB.getLoginStatus(function(response) {
  //    if (response.status === 'connected') {
  //        da.popup('facebook-share');
  //    }
  //    else {
  //        FB.login();
  //    }
  //});

  FB.login(function (response) {
    if (response.status === 'connected') {
      if (response.authResponse.grantedScopes.indexOf('publish_actions') > -1
        && response.authResponse.grantedScopes.indexOf('user_photos') > -1) { //if permissions were granted
        da.popup('facebook-share', 'not-subscribed');
      } else {
        //todo not all permissions granted
      }
      // Logged into your app and Facebook.
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
    } else {
      // The person is not logged into Facebook, so we're not sure if
      // they are logged into this app or not.
    }
  }, {
    scope: 'publish_actions, user_photos',
    return_scopes: true
  });
});

$(document).on('click', '.js-facebook-link-subscriber', function (e) {
  e.preventDefault();
  FB.login(function (response) {
    if (response.status === 'connected') {
      if (response.authResponse.grantedScopes.indexOf('publish_actions') > -1
        && response.authResponse.grantedScopes.indexOf('user_photos') > -1) {
        da.popup('facebook-share', 'subscribed');
      } else {
        //todo not all permissions granted
      }
    }
  }, {
    scope: 'publish_actions, user_photos',
    return_scopes: true
  });
});

$(document).on('click', '.twitter-link', function (e) {
  e.preventDefault();
  var accessToken = "9f3723a746345ee9da7ed2e65235fdbe8e28795b";
  $.getJSON(
    "https://api-ssl.bitly.com/v3/shorten?access_token=" + accessToken + "&longUrl=" + encodeURIComponent(da.photoPage ? window.location.href : da.photoPreview.currentPhoto.obj.publicURL),
    {},
    function (response) {
      if (response.status_code === 200) {
        da.shortURL = response.data.url;
      } else {
        console.error('Bitly: ' + response.status_code + ':' + response.status_txt);
        da.shortURL = location.href;
      }

      var width = 575,
        height = 200,
        left = ($(window).width() - width) / 2,
        top = ($(window).height() - height) / 2,
        url = "http://twitter.com/share?url=" + da.shortURL || (da.photoPage ? window.location.href : da.photoPreview.currentPhoto.obj.publicURL),
        opts = 'status=1' +
          ',width=' + width +
          ',height=' + height +
          ',top=' + top +
          ',left=' + left;
      var newWindow = window.open(url, 'twitter', opts);
      if (!newWindow) window.location.href = url;
    }
  );

});

$(document).on('click', '.pinterest-link', function (e) {
  e.preventDefault();
  var imgURL = (da.photoPreview.currentPhoto.obj ? da.photoPreview.currentPhoto.obj.watermarkURL : da.photoPage.watermarkURL).indexOf('drewaltizer.com') > -1 ? (da.photoPreview.currentPhoto.obj ? da.photoPreview.currentPhoto.obj.watermarkURL : da.photoPage.watermarkURL) : (window.location.origin + (da.photoPreview.currentPhoto.obj ? da.photoPreview.currentPhoto.obj.watermarkURL : da.photoPage.watermarkURL)),
    text = (da.eventDescription && da.eventDescription.length) ? encodeURIComponent(da.eventDescription) : (da.photoPage ? (da.photoPage.eventDescription && da.photoPage.eventDescription.length ? encodeURIComponent(da.photoPage.eventDescription) : '') : ''),
    width = 700,
    height = 500,
    left = ($(window).width() - width) / 2,
    top = ($(window).height() - height) / 2,
    opts = 'status=1' +
      ',width=' + width +
      ',height=' + height +
      ',top=' + top +
      ',left=' + left,
    service = "http://pinterest.com/pin/create/button/?url=" + window.location.href + "&media=" + encodeURIComponent(imgURL) + "&description=" + text;
  window.open(service, 'share-dialog', opts);
});

if ($('.js-login-page').length) {
  da.loginPage = {
    signIn: {
      $form: $('.sign-in-page'),
      $toggleLink: $('.js-sign-up-show')
    },
    signUp: {
      $form: $('.sign-up-page'),
      $toggleLink: $('.js-sign-in-show')
    }
  };

  if (showSignUpForm) {
    da.loginPage.signUp.$form.show();
  } else {
    da.loginPage.signIn.$form.show();
  }

  da.loginPage.signIn.$toggleLink.on('click', function (e) {
    e.preventDefault();
    da.loginPage.signIn.$form.hide();
    da.loginPage.signUp.$form.show();
  });

  da.loginPage.signUp.$toggleLink.on('click', function (e) {
    e.preventDefault();
    da.loginPage.signUp.$form.hide();
    da.loginPage.signIn.$form.show();
  });
}

$(document).on('click', '.js-download-link-embed', function (e) {
  e.preventDefault();
  e.stopPropagation();
  window.open(this.dataset.link, '_blank');
});
$(document).on('click', '.js-popup.download-link-embed', function (e) {
  e.preventDefault();
  e.stopPropagation();
});

if ($('.about-block').length) {
  if (window.innerWidth > 801) {
    var $circles = $('.circle');
    var circlesAmount = $circles.length;
    var counter = 0;
    var circlesInterval = setInterval(function () {
      $circles.removeClass('acitve');
      $circles.eq(counter).trigger('mouseover', 'emulated');
      (counter + 1 < circlesAmount) ? counter++ : counter = 0;
    }, 2000);
    $(document).on('mouseover', '.circle', function (e, emulated) {
      emulated = typeof emulated !== 'undefined';
      !emulated ? clearInterval(circlesInterval) : '';
      var circle = $('.circle');

      circle.removeClass('active');
      $('.number-list li').removeClass('active');

      var number = ($.inArray(this, circle)) + 1;

      $(this).addClass('active');
      $('.number-list li:nth-child(' + number + ')').addClass('active');
    });
  }
}

da.photoPreview.socialLeft = true;

function changeSocialSide() {
  da.photoPreview.photo = $('.photo-block');

  if (da.photoPreview.photo.length) {
    da.photoPreview.photo.leftCoord = da.photoPreview.photo.offset().left;
    da.photoPreview.socialBlock = $('.social-block');

    if (da.photoPreview.photo.leftCoord > 150) {
      if (da.photoPreview.socialLeft) {
        da.photoPreview.socialBlock.addClass('left-move');
        da.photoPreview.socialLeft = false;
      }
    } else {
      if (!da.photoPreview.socialLeft) {
        da.photoPreview.socialBlock.removeClass('left-move');
        da.photoPreview.socialLeft = true;
      }
    }
  }
}

changeSocialSide();

$(window).resize(function () {
  changeSocialSide();
});

function nameCorrectionCallback(result) {
  if (!result.isSuccess || result.response.status !== 200) return;
  $('.js-submit-correction').hide();
}

function whoAmIcallback(result) {
  if (!result.isSuccess || result.response.status !== 200) return;
  $('.js-submit-tagging').hide();
}

function sharePhotoEmailCallback(result) {
  if (!result.isSuccess || result.response.status !== 200) return;
  var $sendBtn = $('.js-share-photo-email');
  $sendBtn.val($sendBtn.data('sent')).prop('disabled', true);
  setTimeout(function () {
    window.closePopup();
  }, 1000)
}

function signUpCallback(result) {
  if (!result.isSuccess || result.response.status !== 200) return;
  if (window.ga) {
    window.ga('send', { hitType: 'event', eventCategory: 'Sign Up', eventAction: 'Success' });
  }
}

function facebookLogin() {
  if (FB) {
    FB.login(facebookLoginCallback, {
      scope: facebookVariables.scope,
      return_scopes: true
    });
  } else {
    console.warn('Facebook is not initialized yet');
  }
}

function facebookLoginCallback(response) {
  if (!response || !response.authResponse) return;
  if (response.authResponse.grantedScopes && response.authResponse.grantedScopes.indexOf("email") === -1) {
    FB.login(facebookLoginCallback, {
      scope: facebookVariables.requiredScope,
      return_scopes: true,
      auth_type: "rerequest"
    });
  } else {
    if (response.status === 'connected') {
      FB.api('/me', function (userData) {
        spiral.ajax({
          url: '/signup/facebook',
          data: {
            accessToken: response.authResponse.accessToken,
            userData: userData
          },
          processAnswer: function (ans) {
            if (!ans.isSuccess || ans.response.status !== 200) {
              da.modalMessage(ans, 2000);
            }
            if (ans.response.cleanCookies && ans.response.status >= 400 && ans.response.status < 500) {
              FB.logout(function (response) {
              });
            }
          }
        });
      });
      // Logged into your app and Facebook.
    } else if (response.status === 'not_authorized') {
      // The person is logged into Facebook, but not your app.
    } else {
      // The person is not logged into Facebook, so we're not sure if they are logged into this app or not.
    }
  }
}

$('.js-facebook-login').on('click', facebookLogin);

var $scroller = $('#scroller');
if ($scroller.length > 0) {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 0) {
      $scroller.fadeIn();
    } else {
      $scroller.fadeOut();
    }
  });
  $scroller.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 400);
    return false;
  });
}

//if not search-page, then make logic for mobile search form
if (!$('.js-search-results').length && da.currentScreenType === 'mobile') {
  var $searchForm = $('.js-search-form');
  var $searchInput = $searchForm.find('.search-input');

  $('.js-category-list a').on('click touchstart', function (e) {
    e.preventDefault();
    $('.js-category-link').attr('data-search-type', $(this).attr('data-search-option')).find('span').text(searchTypeMap[$(this).attr('data-search-option')]);
    $('.js-category-list').removeClass('open');
  });

  var makeSearch = function () {
    event.preventDefault();
    location.href = "/search?type=" + $searchForm.find('.js-category-link').attr('data-search-type') + "&query=" + $searchInput.val();
  }

  $searchForm.on('submit', function () {
    makeSearch();
  });

  $searchInput.on('focusout', function () {
    makeSearch();
  });
}

if (da.currentScreenType === 'mobile') {
}

if ($('.js-more-photos').length && da.currentScreenType === 'mobile') {
  $('.js-more-photos').each(function () {
    if ($(this).offset().left + $(this).width() / 2 > window.innerWidth / 2) $(this).addClass('tooltip-left');
  });

  $('.js-more-photos').on('click touchstart', function (e) {
    e.preventDefault();
    var $t = $(this);
    if ($t.hasClass('hovered')) {
      $(this).removeClass('hovered');
    } else {
      $('.js-more-photos').removeClass('hovered');
      $(this).addClass('hovered');
    }
  });

  $(document).on('click', function (e) {
    if (!$(e.target).closest('.js-more-photos').length) {
      $('.js-more-photos').removeClass('hovered');
    }
  });
}

if (!localStorage.topBanner && $('.js-top-banner-text').text().match(/[^\s]/g)) {
  da.$body.addClass('with-banner');
  da.topBanner.height = $('.top-banner').outerHeight();
  da.$body.css('margin-top', da.topBanner.height);
  $('#header').css('top', da.topBanner.height);
  $('.js-sort').css('top', 8 + da.topBanner.height);
  $(document).on('click', '.js-close-banner', function (e) {
    e.preventDefault();
    da.$body.removeClass('with-banner');
    da.$body.css('margin-top', 0);
    $('#header').css('top', 0);
    $('.js-sort').css('top', 8);
    $('.js-instructional-box').css('top', '-=' + da.topBanner.height);
    $('.filters-icon').css('top', '-=' + da.topBanner.height);
  });
  window.addEventListener('resize', function () {
    da.topBanner.height = $('.top-banner').outerHeight();
    da.$body.css('margin-top', da.topBanner.height);
    $('#header').css('top', da.topBanner.height);
    $('.js-sort').css('top', 8 + da.topBanner.height);
  });
  localStorage.setItem('topBanner', false);
}

da.$instructionalBox = [];
da.instructionalBoxNumber = 0;
da.closeFirstTimeOverlay = function (event) {
  if (da.gallery && da.gallery.openPhotoOnLoad) return;
  if (event) event.preventDefault();
  $('.instructional-overlay').remove();
  $('.js-instructional-box').remove();
  da.$body.removeClass('with-overlay');
  $(document).unbind('click touchstart', da.closeFirstTimeOverlay)
};

da.setInstructionalBoxCoord = function () {
  var socialBlockCoord = document.querySelector('.social-block').getBoundingClientRect();
  var tabsBlockCoord = document.querySelector('.tabs-block').getBoundingClientRect();
  var materialBlockCoord = document.querySelector('.js-select-material').getBoundingClientRect();

  da.$instructionalBox[ 0 ].css(
    {
      left: socialBlockCoord.left - 2,
      top: socialBlockCoord.top
    }
  );
  da.$instructionalBox[ 1 ].css(
    {
      left: tabsBlockCoord.left,
      top: tabsBlockCoord.top - 28
    }
  );
  da.$instructionalBox[ 2 ].css(
    {
      left: tabsBlockCoord.left,
      top: tabsBlockCoord.top + 80
    }
  );
  da.$instructionalBox[ 3 ].css(
    {
      left: materialBlockCoord.left,
      top: materialBlockCoord.top
    }
  );
};

da.$firstTimeOverlay = $("<div class='js-instructional-overlay instructional-overlay'><a href='#' class='close-btn js-close-btn'><i class='icon icon-cancel'></i></a></div>");

if (window.location.href.indexOf('/photo') >= 0) {
  if (!localStorage.socialInstructions) {

    if (da.currentScreenType === 'mobile') {
      da.$instructionalBox[ 0 ] = $("" +
        "<div class='instructional-box js-instructional-box photo-instructions'>" +
        "<p>Choose if you want to purchase a print or a digital download of this photo</p>" +
        "<p>Choose what type of print material <br> you would like</p>" +
        "<p>Click on the size of the print, select the number of prints and then click add to cart</p>" +
        "<p>Click Share to download, share to social media or email an image to yourself</p></div>");

      da.ecommerce.$el.after(da.$instructionalBox);

    } else {
      da.$instructionalBox[ 0 ] = $("" +
        "<div class='instructional-box js-instructional-box social-instructions'>" +
        "   <img src='/media/images/social-block.png' srcset='/media/images/social-block-2x.png 2x' width='54'/><p>Click the icon to download, share to social media or email an image to yourself</p></div>"),
        da.$instructionalBox[ 1 ] = $("" +
          "<div class='instructional-box js-instructional-box tabs-instructions'>" +
          "   <p>Choose if you want to purchase a print or a digital download of this photo</p><img src='/media/images/tabs.png' srcset='/media/images/tabs-2x.png 2x' width='174'/></div>"),
        da.$instructionalBox[ 2 ] = $("" +
          "<div class='instructional-box js-instructional-box sizes-instructions'>" +
          "   <img src='/media/images/sizes.png' srcset='/media/images/sizes-2x.png 2x' width='415'/><p>Click on the size of the print, select the number of prints and then click add to cart</p></div>"),
        da.$instructionalBox[ 3 ] = $("" +
          "<div class='instructional-box js-instructional-box material-instructions'>" +
          "   <img src='/media/images/material.png' srcset='/media/images/material-2x.png 2x' width='90'/><p>Choose what type of print material <br> you would like</p></div>");

      da.$body.addClass('with-overlay');
      da.$body.append(da.$firstTimeOverlay);

      setTimeout(function () {
        da.setInstructionalBoxCoord();
        da.$body.append(da.$instructionalBox);
        $(document).on('click touchstart', da.closeFirstTimeOverlay);
      }, 500);

      $(window).on('resize', function () {
        da.currentScreenType = screenType();

        if (da.currentScreenType === 'desktop' && da.$body.hasClass('with-overlay')) {
          da.setInstructionalBoxCoord();
        } else {
          da.closeFirstTimeOverlay();
        }
      });
    }
    localStorage.socialInstructions = true;
  }
}
//swipe menu
//da.swipeElements = {
//    touchStartLocation: '',
//    touchID: '',
//    startTime: '',
//    touchEndLocation: '',
//    endTime: '',
//    scrolling: false,
//    timeThreshold: 500,
//    distanceThreshold: 50
//};
//
//$('.js-swipe-area, .js-navigation').on('touchstart.swipeEvent', function (e) {
//    da.swipeElements.touchStartLocation = {
//        x: e.originalEvent.touches[0].pageX,
//        y: e.originalEvent.touches[0].pageY
//    };
//    da.swipeElements.touchID = e.originalEvent.touches[0].identifier;
//    da.swipeElements.startTime = new Date().getTime();
//});
//
//$('.js-swipe-area, .js-navigation').on('touchend.swipeEvent touchcancel.swipeEvent', function (e) {
//    var $this = $(this);
//    if (e.type !== 'touchcancel') {
//        if(!e.originalEvent.changedTouches) return;
//        for (var i=0; i < e.originalEvent.changedTouches.length; i++) {
//            var t = e.originalEvent.changedTouches[i];
//
//            if (t.identifier == da.swipeElements.touchID) {
//                da.swipeElements.touchEndLocation = {
//                    x: t.pageX,
//                    y: t.pageY
//                };
//                break;
//            }
//        }
//
//        e.preventDefault();
//        da.swipeElements.endTime = new Date().getTime();
//
//        if (da.swipeElements.endTime - da.swipeElements.startTime > da.swipeElements.timeThreshold) return false;
//
//        if (Math.abs(da.swipeElements.touchStartLocation.x - da.swipeElements.touchEndLocation.x) > da.swipeElements.distanceThreshold && Math.abs(da.swipeElements.touchStartLocation.y - da.swipeElements.touchEndLocation.y) < da.swipeElements.distanceThreshold) {
//            if (da.swipeElements.touchStartLocation.x > da.swipeElements.touchEndLocation.x) $this.trigger('swipeLeft');
//            else $this.trigger('swipeRight');
//        } else if (Math.abs(da.swipeElements.touchStartLocation.x - da.swipeElements.touchEndLocation.x) < da.swipeElements.distanceThreshold && Math.abs(da.swipeElements.touchStartLocation.y - da.swipeElements.touchEndLocation.y) > da.swipeElements.distanceThreshold) {
//            if (da.swipeElements.touchStartLocation.y > da.swipeElements.touchEndLocation.y) $this.trigger('swipeUp');
//            else $this.trigger('swipeDown');
//        }
//    }
//});
//
//$('.js-swipe-area').on('swipeRight', function () {
//    $('.js-navigation').addClass('show');
//    da.$body.addClass('menu-open');
//    $('.js-mobile-menu').addClass('open');
//    da.$body.append("<div id='fade-m'></div>");
//});
//
//$('.js-navigation').on('swipeLeft', function () {
//    $('.js-navigation').removeClass('show');
//    da.$body.removeClass('menu-open');
//    $('.js-mobile-menu').removeClass('open');
//    $('#fade-m').remove();
//});
//
//$('.js-navigation').on('touchmove', function() {
//    da.swipeElements.scrolling = true;
//});
//
//$('.js-navigation a').on('touchend', function() {
//    if(da.swipeElements.scrolling === true) {
//        da.swipeElements.scrolling = false;
//        return;
//    }
//    var $this = $(this);
//    if ($this.hasClass('js-popup')) {
//        $this.trigger('click');
//    } else {
//        var href = $this.attr('href');
//        location.href = href;
//    }
//});

$('.js-n-popup').on('click', function (e) {
  e.preventDefault();

  $('.js-m-popup').addClass('show');
  da.$body.append("<div class='fade'></div>");
})

$('.close-link').on('click', function () {
  $('.js-m-popup').removeClass('show');
  $('.fade').remove();
})

$(document).on("click", function (e) {
  if ($(e.target).hasClass('fade')) {
    $('.js-m-popup').removeClass('show');
    $('.fade').remove();
  }
})
